<template>
  <div class="brands">
    <div class="img-block mb-14">
      <picture class="block w-full">
        <img src="/imgs/content/photos/brands.jpg" alt="Holy Chic Brands" class="block w-full">
      </picture>
    </div>

    <section class="mb-14">
      <div class="container">
        <h2 class="text-4xl font-pt-mono mb-4">{{ $t('wishlist') }}</h2>
        <p class="global-text-sm text-sm  mb-8 font-qanelas-light">
          {{ $t('wishlist_par_1') }}
        </p>
      </div>
      <picture class="block w-full mb-8 lg:w-2/3 lg:mx-auto xl:w-1/2">
        <img src="/imgs/brands.png" alt="Holy Chic Brands" class="block w-full">
      </picture>



      <div class="container flex justify-center">
        <div class="send-photo w-11/12">
          <div class="send-photo__arrow">
            <picture class="trigger-photo__arrow" >
              <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="child-stroke-red">
                <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
                <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
                <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
              </svg>
            </picture>
          </div>
          <a class="send-photo__link block flex justify-center items-center bg-red  rounded-full" href="https://www.instagram.com/holy_chic.md/" target="_blank">
            <span class="text-white font-qanelas-bold uppercase text-center text-xs" v-html="$t('send_photos')"></span>
          </a>
          <div class="send-photo__arrow">
            <picture class="trigger-photo__arrow" >
              <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="child-stroke-red origin-center rotate-180 transform">
                <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
                <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
                <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
              </svg>
            </picture>
          </div>
        </div>
      </div>
    </section>

    <div class="bg-red py-10 text-white mb-10">
      <div class="container ">
        <p class="half-bordered-paragraph half-bordered-paragraph--white font-pt-mono text-2xl mb-6 leading-7"
          v-html="$t('ban')"
        ></p>
      </div>
    </div>

    <div class="container">
      <p class="global-text-sm text-sm  mb-8 font-qanelas-light">
        {{ $t('wishlist_par_2') }}
      </p>
      <p class="half-bordered-paragraph font-pt-mono text-xl mb-6 flex items-center">
        <picture class="brands__medal-img flex-shrink-0 block mr-2 mt-2">
          <img src="/imgs/medal.png" alt="" class="blocl w-full">
        </picture>
        <span class="flex-grow leading-6">Entrupy verified <br>
          trusted seller</span>
      </p>
    </div>


    <div class="img-block mb-10">
      <picture class="block w-full">
        <img src="/imgs/content/photos/brands2.jpg" alt="Holy Chic Brands" class="block w-full">
      </picture>
    </div>


    <section>
      <div class="container">
        <h2 class="text-4xl font-pt-mono mb-4 leading-7">{{ $t('blacklist') }}</h2>
        <p class="global-text-sm text-sm  mb-5 font-qanelas-light">{{ $t('ban_par') }}</p>
        <p class="text-xs mb-10 leading-6 xl:text-sm">ZARA, MANGO, H&M, TOPSHOP, STRADIVARIUS, MONSOON, ABERCROMBIE & FITCH, OASIS, TATUUM, ASOS,  CARLO PAZOLINI, CORSO COMO, ALDO, ADAMI, CENTRO, ЭКОНИКА, PROMOD, PULL&BEAR, MOHITO, TERRANOVA, MARKS & SPENCER, BENETTON, BERSHKA, GUESS, PEPE, JLO, BEBE {{ $t('etc') }}</p>

        <a href="javascript:void(0)" class="trigger-link justify-between mb-12" @click="$router.go(-1)" rel="nofollow">
          <span class="trigger-link__link-text order-2 grow-0 flex-grow-0" v-html="$t('go_back_br')"></span>
          <picture class="trigger-link__arrow order-1 to-back">
            <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="child-stroke-black origin-center rotate-180 transform">
              <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
              <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
              <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
            </svg>
          </picture>
        </a>
      </div>
    </section>





  </div>

</template>
